var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda siderbar",staticStyle:{"max-width":"100%"}},[_c('b-row',{staticClass:"height"},[_c('sidebar-admin'),_c('b-col',{staticClass:"content"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Eventos")])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn large blue",attrs:{"to":{
              name: 'createEvent',
            }}},[_vm._v("Novo evento")])],1)],1),(_vm.loading)?_c('b-row',{attrs:{"align":"center"}},[_c('b-col',{staticClass:"mt-5"},[_c('b-spinner',{attrs:{"variant":"success","label":"Carregando..."}})],1)],1):_vm._e(),(!_vm.loading && _vm.events && _vm.events.length > 0)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-container',{attrs:{"fluid":""}},[(_vm.events.length > 0)?_c('b-row',{staticClass:"header-list-teacher"},[_c('b-col',{attrs:{"md":"2","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Título")])]),_c('b-col',{attrs:{"md":"3","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Descrição")])]),_c('b-col',{attrs:{"md":"2","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Disponível até")])]),_c('b-col',{attrs:{"md":"2","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Destinatários")])]),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Visualizar")])]),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Editar")])]),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('p',{staticClass:"bold"},[_vm._v("Excluir")])])],1):_vm._e()],1),_c('b-container',{attrs:{"fluid":""}},_vm._l((_vm.events),function(item){return _c('b-row',{key:item.id,staticClass:"list-teacher",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"2","align":"center"}},[_c('p',[_vm._v(_vm._s(item.title))])]),_c('b-col',{attrs:{"md":"3","align":"center"}},[_c('p',[_vm._v(" "+_vm._s(item.description.slice(0, 100))+_vm._s(item.description.length > 100 ? "..." : null)+" ")])]),_c('b-col',{attrs:{"md":"2","align":"center"}},[_c('p',[_vm._v(_vm._s(item.available_until))])]),_c('b-col',{attrs:{"md":"2","align":"center"}},_vm._l((item.targets),function(target,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(target.Resource)+" "+_vm._s(target.SubResource ? " - " : null)+" "+_vm._s(target.SubResource)+" ")])}),0),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'showEvent',
                    params: {
                      id: item.id,
                    },
                  }}},[_c('ViewIcon')],1)],1),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'updateEvent',
                    params: {
                      id: item.id,
                    },
                  }}},[_c('EditIcon')],1)],1),_c('b-col',{attrs:{"md":"1","align":"center"}},[_c('a',{on:{"click":function () { return _vm.deleteEvent(item.id); }}},[_c('DeleteIcon')],1)])],1)}),1)],1)],1):_vm._e(),(!_vm.events || _vm.events.length == 0)?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('p',[_vm._v("Não há eventos cadastrados.")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }