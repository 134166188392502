<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <sidebar-admin />
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Eventos</h2>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="btn large blue"
              :to="{
                name: 'createEvent',
              }"
              >Novo evento</b-button
            >
          </b-col>
        </b-row>

        <b-row align="center" v-if="loading">
          <b-col class="mt-5">
            <b-spinner variant="success" label="Carregando..."></b-spinner>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-if="!loading && events && events.length > 0">
          <b-col>
            <b-container fluid>
              <b-row class="header-list-teacher" v-if="events.length > 0">
                <b-col md="2" align="center">
                  <p class="bold">Título</p>
                </b-col>
                <b-col md="3" align="center">
                  <p class="bold">Descrição</p>
                </b-col>

                <b-col md="2" align="center">
                  <p class="bold">Disponível até</p>
                </b-col>

                <b-col md="2" align="center">
                  <p class="bold">Destinatários</p>
                </b-col>

                <b-col md="1" align="center">
                  <p class="bold">Visualizar</p>
                </b-col>

                <b-col md="1" align="center">
                  <p class="bold">Editar</p>
                </b-col>

                <b-col md="1" align="center">
                  <p class="bold">Excluir</p>
                </b-col>
              </b-row>
            </b-container>

            <b-container fluid>
              <b-row
                align-v="center"
                class="list-teacher"
                v-for="item in events"
                v-bind:key="item.id"
              >
                <b-col md="2" align="center">
                  <p>{{ item.title }}</p>
                </b-col>

                <b-col md="3" align="center">
                  <p>
                    {{ item.description.slice(0, 100)
                    }}{{ item.description.length > 100 ? "..." : null }}
                  </p>
                </b-col>

                <b-col md="2" align="center">
                  <p>{{ item.available_until }}</p>
                </b-col>

                <b-col md="2" align="center">
                  <p v-for="(target, index) in item.targets" :key="index">
                    {{ target.Resource }}
                    {{ target.SubResource ? " - " : null }}
                    {{ target.SubResource }}
                  </p>
                </b-col>

                <b-col md="1" align="center">
                  <router-link
                    :to="{
                      name: 'showEvent',
                      params: {
                        id: item.id,
                      },
                    }"
                  >
                    <ViewIcon />
                  </router-link>
                </b-col>

                <b-col md="1" align="center">
                  <router-link
                    :to="{
                      name: 'updateEvent',
                      params: {
                        id: item.id,
                      },
                    }"
                  >
                    <EditIcon />
                  </router-link>
                </b-col>

                <b-col md="1" align="center">
                  <a @click="() => deleteEvent(item.id)">
                    <DeleteIcon />
                  </a>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>

        <b-row v-if="!events || events.length == 0">
          <b-col class="text-center">
            <p>Não há eventos cadastrados.</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda";
import EditIcon from "@/components/svg/EditIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import SidebarAdmin from "../../../components/SidebarAdmin.vue";
import moment from "moment";

export default {
  components: {
    EditIcon,
    ViewIcon,
    DeleteIcon,
    SidebarAdmin,
  },

  data() {
    return {
      name: "eventos",
      loading: false,

      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Eventos",
          href: "/administracao/eventos",
        },
      ],
      events: [],
    };
  },

  methods: {
    async getEvents() {
      this.loading = true;

      try {
        await httpRda.get("/events").then((response) => {
          this.events = response.data.events.events;
          this.events = this.events.map((event) => {
            event.available_until = moment(event.available_until).format(
              "DD/MM/YYYY"
            );
            return event;
          });
          this.loading = false;
        });
      } catch (e) {
        alert(e);
        this.loading = false;
      }
    },

    async deleteEvent(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await httpRda.delete(`/events/${id}`).then(() => {
                this.getEvents();
              });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel remover!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },
  },

  created() {
    this.getEvents();
  },
};
</script>

<style></style>
